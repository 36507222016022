.error-page-container {
	// padding-top: 154px;
	padding-bottom: 100px;
	padding-top: 124px;

	@media only screen and (max-width: @screen-tablet-max) {
		padding-top: 84px;
	}

	@media only screen and (max-width: @screen-mobile-max) {
		padding-top: 55px;
	}

	@media only screen and (max-width: @screen-xl) {
		gap: 40px;
	}

	@media only screen and (max-width: @screen-lg) {
		flex-direction: column;
	}

	@media only screen and (max-width: @screen-lg) {
		gap: 20px;
	}

	.status {
		color: #fff;
		font-family: 'DM Sans';
		font-size: 80px;
		font-weight: 700;
		letter-spacing: 0.2px;
	}
	.title {
		color: #fff;
		font-family: 'DM Sans';
		font-size: 40px;
		font-weight: 400;
		letter-spacing: 0.2px;
		margin-top: 8px;
	}
	.description {
		color: rgba(255, 255, 255, 0.6);
		font-family: 'DM Sans';
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0.2px;
		margin-top: 40px;
	}
	.home-btn {
		padding: 21px 34px;
		color: #fff;
		text-align: center;
		font-family: 'DM Sans';
		font-size: 24px;
		font-weight: 500;
		margin-top: 60px;
	}
	.image {
		max-width: 100%;
		width: 1920px;
		height: auto;
	}
}

body:has([class~='header-connect-wallet']) {
	.error-page-container {
		padding-top: 156px;

		@media only screen and (max-width: @screen-tablet-max) {
			padding-top: 116px;
		}

		@media only screen and (max-width: @screen-mobile-max) {
			padding-top: 87px;
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';